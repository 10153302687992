import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MediabankFile } from './mediabank-models';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import {Upload} from 'tus-js-client';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

export interface FileStatus {
  filename: string;
  progress: number;
  hash: string;
  uuid: string;
}

@Injectable({
  providedIn: 'root'
})
export class MediabankService {

  private uploadStatus = new Subject<FileStatus[]>();
  uploadProgress = this.uploadStatus.asObservable();
  public uploadProgressAsNumber: Subject<number> = new Subject<number>();

  fileStatusArr: FileStatus[] = [];

  resetUploadStatus(): void {
    this.uploadStatus = new Subject<FileStatus[]>();
    this.uploadProgress = this.uploadStatus.asObservable();
    this.fileStatusArr = [];
  }

  constructor(private http: HttpClient, private config: AppConfigService, private authService: AuthenticationService) { }

  public TYPES = {
    UNDEFINED: -1,
    IMAGE: 1,
    VIDEO: 2,
    AUDIO: 3,
    PDF: 4,
    WORD: 5,
    EXCEL: 6,
    POWERPOINT: 7,
    SUBTITLE: 8,
    EXTERNAL_VIDEO: 9
   };

  /**
   * GetTypeName
    type: number   */
  public GetTypeName(type: number) {
    if(type == this.TYPES.IMAGE) return "Image";
    else if(type == this.TYPES.VIDEO) return "Video";
    else if(type == this.TYPES.AUDIO) return "Audio";
    else if(type == this.TYPES.PDF) return "Pdf";
    else if(type == this.TYPES.WORD) return "Word";
    else if(type == this.TYPES.EXCEL) return "Excel";
    else if(type == this.TYPES.POWERPOINT) return "PowerPoint";
    else if(type == this.TYPES.SUBTITLE) return "Subtitle";
    else if(type == this.TYPES.EXTERNAL_VIDEO) return "External video";
    else return "Undefined";
  }

  public SaveTus(mediabankFile: MediabankFile, file: File, successCallback: (upload) => void, errorCallback: (error) => void) {
    mediabankFile.file = undefined;
    const fileStatus: FileStatus = {filename: file.name, progress: 0, hash: '', uuid: ''};
    this.fileStatusArr.push(fileStatus);

    this.uploadStatus.next(this.fileStatusArr);

    const upload = new Upload(file, {
      endpoint: this.config.apiEndpoint + "uploadfiletus/",
      retryDelays: [0, 3000, 6000, 12000, 24000],
      chunkSize: 2000000,
      metadata: {
        filename: file.name,
        filetype: file.type,
        userId: this.authService.currentUserValue.user?.id,
        mediabankFile: JSON.stringify(mediabankFile)
      },
      onError: async (error) => {
        console.log(error);
        errorCallback(error);
      },
      onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
        this.fileStatusArr.forEach(value => {
          if (value.filename === file.name) {
            value.progress = Math.floor(bytesAccepted / bytesTotal * 100);
            value.uuid = upload.url.split('/').slice(-1)[0];
            this.uploadProgressAsNumber.next(Math.floor(bytesAccepted / bytesTotal * 100));
          }
        });
        this.uploadStatus.next(this.fileStatusArr);
      },
      onSuccess: async () => {
        this.fileStatusArr.forEach(value => {
          if (value.filename === file.name) {
            value.progress = 100;
          }
        });
        this.uploadStatus.next(this.fileStatusArr);

        successCallback(upload);        
      }
    });
    upload.start();

  }

  /**
   * Upload
   */
  public Save(mediabankFile: MediabankFile) {
    if(mediabankFile.id && mediabankFile.id.length > 0) {
      return this.Update(mediabankFile);
    }

    const formData = new FormData();
    if( mediabankFile.file) formData.append('file', mediabankFile.file);
    if(mediabankFile.name && mediabankFile.name !== "") formData.append('name', mediabankFile.name);
    if(mediabankFile.thumbnail && mediabankFile.thumbnail !== "") formData.append('thumbnail', mediabankFile.thumbnail);
    if(mediabankFile.description && mediabankFile.description !== "") formData.append('description', mediabankFile.description);
    if(mediabankFile.urlRewrite && mediabankFile.urlRewrite !== "") formData.append('urlRewrite', mediabankFile.urlRewrite);
    if(mediabankFile.id && mediabankFile.id.length > 0) formData.append('id', mediabankFile.id.toString());
    if(mediabankFile.relatedToId && mediabankFile.relatedToId.length > 0) formData.append('relatedToId', mediabankFile.relatedToId.toString());

    if(mediabankFile.iso2Culture && mediabankFile.iso2Culture.length > 0) formData.append('iso2Culture', mediabankFile.iso2Culture.toString());
    if(mediabankFile.iso4Culture && mediabankFile.iso4Culture.length > 0) formData.append('iso4Culture', mediabankFile.iso4Culture.toString());

    if(mediabankFile.type && mediabankFile.type > 0) formData.append('type', mediabankFile.type.toString());
    if(mediabankFile.fileUrl && mediabankFile.fileUrl !== "") formData.append('fileUrl', mediabankFile.fileUrl);

    formData.append('publishToYoutube', mediabankFile.publishToYoutube.toString());

    formData.append('isPublic', mediabankFile.isPublic.toString());

    return this.http.post(this.config.adminApiEndpoint + 'api-mediabank/mediabank/save', formData, { 
      headers: new HttpHeaders({ timeout: '6000000' })
     });
  }

  public youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  /**
   * Update
      mediabankFile: MediabankFile   */
  public Update(mediabankFile: MediabankFile) {
    return this.http.post(this.config.adminApiEndpoint + 'api-mediabank/mediabank/update', mediabankFile);
  }

  /**
   * GetMediabankFiles
   */
  public GetMediabankFiles(types: number[]) {
    return this.http.get(this.config.adminApiEndpoint + 'api-mediabank/mediabank/GetAllByType?types=' + types.join(","));
  }

  /**
   * GetAllMediabankFiles
   */
  public GetAllMediabankFiles() {
    return this.http.get(this.config.adminApiEndpoint + 'api-mediabank/mediabank/GetAll');
  }

  /**
   * GetAllPaged
pageSize: number = 25, pageIndex: number = 0, type?: number   */
  public GetAllPaged(pageSize: number = 25, pageIndex: number = 0, type?: number) {
    return this.http.get(this.config.adminApiEndpoint + `api-mediabank/mediabank/GetAllPaged?pageSize=${pageSize}&pageIndex=${pageIndex}&type=${type}`);
  }

  /**
   * GetAllMediabankFiles
   */
  public GetAllNonPublic() {
    return this.http.get(this.config.adminApiEndpoint + 'api-mediabank/mediabank/GetAllNonPublic');
  }

  /**
   * GetAllPublic
   */
  public GetAllPublic(pageSize: number = 100, pageIndex: number = 0, orderField: string = '', orderBy: string = '') {
    return this.http.get(this.config.adminApiEndpoint + 
      `api-mediabank/mediabank/GetAllPublic?pageSize=${pageSize}&pageIndex=${pageIndex}&orderField=${orderField}&orderBy=${orderBy}`);
  }

  /**
   * GetMediabankFile
      id: number   */
  public GetMediabankFile(id: string) {
    return this.http.get(this.config.adminApiEndpoint + 'api-mediabank/mediabank/Get?id=' + id.toString());
  }

  /**
   * GetMediabankFileByUrlRewrite
   */
  public GetMediabankFileByUrlRewrite(urlRewrite: string) {
    return this.http.get(this.config.adminApiEndpoint + 'api-mediabank/mediabank/GetByUrl?urlRewrite=' + urlRewrite);
  }

  /**
   * Delete
file: MediabankFile   */
  public Delete(file: MediabankFile) {
    return this.http.delete(this.config.adminApiEndpoint + 'api-mediabank/mediabank/delete?fileId=' + file.id);
  }

  /**
   * TriggerVideoScale
fileId: string   */
  public TriggerVideoScale(fileId: string) {
    return this.http.get(this.config.adminApiEndpoint + 'api-mediabank/mediabank/triggervideoscale?fileId=' + fileId);    
  }


  /**
   * GetPhysicalFile
url: string   */
  public GetPhysicalFile(url: string) {
    return this.http.get(`${this.config.apiEndpoint}cdn/${url}`, { responseType: 'text' as 'json'});
  }

  /**
   * GetEvents
   */
  public GetEvents(mediabankFileId: string, eventType: number) {
    return this.http.get(this.config.apiEndpoint + 
      `api-mediabank/MediabankFileEvent/GetEvents?mediabankFileId=${mediabankFileId}&eventType=${eventType}`);
  }

  GetEventsFullInfo(mediabankFileId: string, eventType: number) {
    return this.http.get(this.config.apiEndpoint + 
      `api-mediabank/MediabankFileEvent/GetEventsFullInfo?mediabankFileId=${mediabankFileId}&eventType=${eventType}`);
  }
}
